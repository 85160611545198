<script lang="ts">
  import { unended } from ".";

  export let permit: Permit;
</script>

{#if unended(permit)}
  <figure class="ical">
    <a href="https://calendar.smartpermit.app/events/{permit.id}"
      >Add to Calendar</a
    >
  </figure>
{/if}
