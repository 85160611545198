<script lang="ts">
  import { defer, identity, capitalize } from "lodash-es";
  import { fetchRequestPhysicalAccess } from "./api";
  import EntryKeypadItem from "./EntryKeypadItem.svelte";
  import AccountMapSection from "$sections/AccountMapSection.svelte";
  import { ended, unstarted } from "$components/permit";

  export let item: any;
  export let permit: Permit;

  let working = false;
  let result = null;
  let error = null;

  $: gate = true == item?.barrier?.includes("gate");
  $: door = item?.door;
  $: parking_entrance = item?.amenity == "parking_entrance";
  $: label = [
    parking_entrance && "Parking Entrance",
    gate && "Gate",
    door && (door != "yes" ? `${capitalize(door)} Door` : "Door"),
  ]
    .filter(identity)
    .join(" ");

  $: pending = unstarted(permit);
  $: expired = ended(permit);

  async function submit(e) {
    //const button = e.target;

    if (working) return;

    working = true;

    //const form = e.target;

    //console.log("callling api");

    const res = await fetchRequestPhysicalAccess(permit, e.target.value);

    //console.log("entry=", item);

    working = false;

    if (res) {
      result = res;
    } else {
      error = true;
      defer(() => alert(`There was an error ${item.action}ing`));
    }

    // sort out result

    // timer reset 5s
    setTimeout(function () {
      error = result = null;
    }, 15 * 1000);
  }
</script>

{#if item}
  {#if item.remote?.enabled}
    <form>
      <h1>{label}</h1>
      <!-- <h2>{label}</h2> -->
      <input type="hidden" name="via" value={item.subject || item.id} />
      <button
        name="via"
        value={item.subject || item.id}
        class={[item.action, item.amenity, result?.result]
          .filter(identity)
          .join(" ")}
        type="submit"
        disabled={working || pending || expired}
        on:click={submit}
        class:working
        class:error
        class:success={result}
        class:pending
        class:expired
      >
        {[
          result?.result,
          pending && "upcoming",
          expired && "expired",
          error && "error",
          //working && "connecting",
          !working && item.action,
        ].find(identity) || ""}
        <!-- <dfn>{accesspoint.action}</dfn> -->
      </button>
    </form>
  {/if}
  {#if item.keypad?.code?.length}
    <h1>
      {[
        item.name,
        //item.remote?.enabled && "Remote Control",
        "Entry Code",
        //capitalize(item.action),
      ]
        .filter((i) => null != i)
        .join(" • ")}
    </h1>

    <EntryKeypadItem item={item.keypad} />
  {/if}
{/if}
