<script lang="ts">
  import EntryItem from "$components/entry/EntryItem.svelte";

  export let permit: Permit;

  $: entry = permit?.entry;

  $: console.log("entry=", entry);
</script>

{#if entry}
  <dl class="entry">
    <dt>Entry</dt>
    {#each Object.values(entry.items) as item}
      <dd>
        <EntryItem {item} {permit} />
      </dd>
    {/each}
  </dl>
{/if}
