<script lang="ts">
  import { datetime } from "$utils/temporal";

  export let permit: Permit;
  $: issued = Temporal.Instant.from(permit.issued.datetime).toZonedDateTimeISO(
    permit.timezone
  );
</script>

<dl>
  <dt>{permit.issued.title}</dt>
  <dd>
    {#if issued}
      <time datetime={permit.issued.datetime}
        ><abbr title={issued.toString()}>{datetime(issued)}</abbr></time
      >
    {/if}
  </dd>
  {#if permit.predecessor}
    <!-- <dt>Changed</dt> -->
    <dd><a href={permit.predecessor.url}>See Previous Permit</a></dd>
  {/if}
  <dt>Record #</dt>
  <dd><data value={permit.id}>{permit.number}</data></dd>
</dl>
